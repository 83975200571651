import axios from "axios";

export async function ShortUrl(url) {
  const encodedParams = new URLSearchParams();
  encodedParams.append("url", url);

  const options = {
    method: "POST",
    url: "https://url-shortener-service.p.rapidapi.com/shorten",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      "X-RapidAPI-Host": "url-shortener-service.p.rapidapi.com",
      "X-RapidAPI-Key": "ddF5iw9N8gmshmGiYjA2cpDBLJyep1kOcKdjsnoj2FmallCvcp",
    },
    data: encodedParams,
  };
  
  return await axios.request(options).then(r => r.data.result_url)
}
