import React, { useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { ShortUrl } from "../api/ShortUrl";

const Input = ({setCopied}) => {
  const [hasSetLink, setHasSetLink] = useState(false);
  const [link, setLink] = useState("");
  const [url, setUrl] = useState("");

  const handleShort = async () => {
    const shortened = await ShortUrl(url);
    setHasSetLink(true);
    setLink(shortened);
  };

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true)
    setTimeout(() => {
        setCopied(false)
    }, 3000)
  };

  return (
    <div className="w-96 flex flex-col items-center justify-center">
      <div className="flex flex-row items-center justify-center relative mb-8">
        <input
          type="text"
          className="w-96 px-4 py-2 rounded-lg focus:outline-none"
          onChange={(e) => setUrl(e.target.value)}
        />
        <button
          onClick={handleShort}
          className="absolute -right-2 px-4 py-2 bg-slate-800 text-center text-white font-semibold uppercase flex justify-center items-center border border-slate-50 rounded-full h-20 w-20 hover:scale-105 transition duration-150"
        >
          Short!
        </button>
      </div>

      {hasSetLink && (
        <div className="w-96 bg-slate-50 px-4 py-2 rounded-lg flex flex-row items-center justify-between">
          <p>{link}</p>
          <AiOutlineCopy
            onClick={() => copy(link)}
            className="text-slate-900 h-6 w-6 cursor-pointer hover:scale-110 transition duration-150"
          />
        </div>
      )}
    </div>
  );
};

export default Input;
