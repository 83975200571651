import { useState } from "react";
import Input from "./components/Input";

function App() {
  const [copied, setCopied] = useState(false);
  return (
    <div className="w-full h-screen bg-slate-800">
      <header className="w-full h-96 flex items-center justify-center flex-col">
        <h1 className="text-5xl text-white font-bold mb-8">
          SIMPLE URL SHORTENER
        </h1>
        <Input setCopied={setCopied} />
        {copied && (
          <h1 className="absolute top-2 right-2 bg-white px-4 py-2 rounded-lg">
            Copied to clipboard!
          </h1>
        )}
      </header>
    </div>
  );
}

export default App;
